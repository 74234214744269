<template>
  <div>
    <div class="text-center">
      <h2>{{ $t('register.text5') }}</h2>
      <p>{{ $t('register.text6') }}</p>
      <p>{{ $t('register.text7') }} </p>
    </div>
    <section id="knowledge-base-content">

      <!-- content -->
      <b-row
       class="kb-search-content-info match-height"
       style="justify-content: center;"
      >
        <b-col
          md="4"
          sm="6"
          class="kb-search-content"
        >
          <b-card
            class="text-center cursor-pointer hoverCard"
            :img-src="require('@/assets/images/profile/brand.png')"
            img-top
            @click="typeRole('brand')"
          >
            <b-card-title class="mt-2 title-card">{{ $t('register.text8') }}</b-card-title>
            <b-card-text class="mt-1">
              {{ $t('register.text9') }}
            </b-card-text>
          </b-card>
        </b-col>
        <b-col
          md="4"
          sm="6"
          class="kb-search-content"
        >
          <b-card
            class="text-center cursor-pointer hoverCard"
            :img-src="require('@/assets/images/profile/creador.png')"
            img-top
            @click="typeRole('creator')"
          >
            <b-card-title class="mt-2 title-card">{{ $t('register.text10') }}</b-card-title>
            <b-card-text class="mt-1">
              {{ $t('register.text11') }}
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BCardText, BCardTitle
} from 'bootstrap-vue'

import service from '@/services/others'
import utils from '@/libs/utils'
import abilities from '@/libs/acl/abilities'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle
  },
  data () {
    return {
      ability: []
    }
  },
  mounted() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
  },
  methods: {
    typeRole (rol) {
      const formData = new FormData()
      formData.append('role', rol)
      this.ability = rol === 'brand' ? abilities.brand : abilities.creator
      this.$ability.update(this.ability)
      service.setRol(formData)
        .then(response => {
          utils.setuserData(response, this.ability)
          if (rol === 'brand') this.$router.push('account-company')
          this.$router.push('additional-information')

        })
    }
  }
}
</script>

<style>
.hoverCard:hover{
  transform: scale(1.05);
  transition: all 500ms;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}
.title-card {
  font-weight: 500;
  font-size: 1.285rem;
  color: #0E131A;
}
</style>